body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans";
  font-family: "Poppins";
}

i { 
  display: flex;
}

.required {
  color: red;
}

.required-indicator {
  color: red;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans SemiBold";
  src: url("fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins ExtraBold";
  src: url("fonts/Poppins/Poppins-ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "Poppins Light";
  src: url("fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins LightItalic";
  src: url("fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
